.navbar{
    width: 100%;
    position: absolute;
    color: white;
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 3rem;
    z-index: 10;
}

.logo{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.icon{
    font-size: 2.5rem;
    color: var(--primary-color);
}

.hamburger{
    display: none;
}

@media screen and (max-width: 960px) {

    .hamburger{
        display: block;
        z-index: 15;
    }

    .navbar{
        flex-direction: column;
    }

    .nav-menu{
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, .8);
        transition: 0.5s ease-in;
    }

    .navbar .active{
        left: 0;
    }

    .navbar li{
        margin-right: 1rem;
        font-size: 2rem;
    }

    .hamburger{
        margin-top: -2.6rem;
        margin-left: 22rem;
        font-size: 1.2rem;
    }
}

