.recovery{
    max-width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .4);
    position: relative;
}

.recovery:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../../assets/recovery-bg.png') center center/cover;
}

.content{
    position: absolute;
    margin-top: 20rem;
    width: 50%;
    color: white;
    left: -12rem;
    padding: 1rem 0;
}

.content span{
    color: var(--primary-color);
}

.content p{
    margin: 2rem 0;
    font-size: 1rem;
}