.cloud{
    width: 100%;
    height: 100vh;
    position: relative;
    background: rgba(0, 0, 0, .1);
}

.cloud:before{
    content: '';
    background: url('../../assets/cloud-bg.png') center center/cover;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 60%;
    top: 30%;
    width: 50%;
    color: white;
}

.content p{
    padding: 1rem 0;
    color: white;
}

.content span{
    color: var(--primary-color);
}

@media screen and (max-width: 940px) {
    .content{
        left: 0;
        width: 100%;
        background: rgba(100, 178, 230, 0.7);
        padding: 1rem;
    }
}