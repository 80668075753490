.footer{
    height: 60vh;
    position: relative;
    background: rgba(255, 255, 255, 0.7);
    padding: 5rem 5rem;
}

.footer:before{
    content: '';
    background: url('../../assets/footer-bg.jpg') no-repeat center center/cover;
    max-width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}

.container{
    display: flex;
    flex-direction: column;
}

.firstsection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.logo>:nth-child(1){
    color: var(--primary-color);
    font-size: 2.5rem;
}

.arrow{
    color: var(--primary-color);
    font-size: 2.5rem;
}

.bottomtext{
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    
}

.col h3{
    margin-bottom: 1rem;
}

.col p{
    padding: 0.7rem 0;
}

.newsletter{
    width: 100%;
}

.newsletter h3{
    font-size: 2rem;
    margin-bottom: 2rem;
}

.newsletter input{
    width: 20rem;
    height: 2.5rem;
    padding: 0 0.5rem;
    border: 1px solid rgb(158, 157, 157);
}

.mail{
    position: absolute;
    font-size: 1.4rem;
    margin: 1rem;
    right: 2rem;
    color: #aaa;
    cursor: pointer;

}

.newsletter>:nth-child(3){
    margin-top: 0.3rem;
}

.social{
    margin-top: 2rem;
}

.socialIcon{
    margin: 1rem 1rem 1rem 0;
    padding: .6rem;
    background-color: var(--primary-dark);
    border-radius: 4px;
    color: #f8f8f8;
    font-size: 3rem;
}

@media screen and (max-width: 940px) {
    .footer{
        max-width: 100%;
        display: flex;
        flex-direction: column;
        background: none;
    }

    .footer:before{
        background: none;
    }

    .bottomtext{
        flex-direction: column;
    }

    .newsletter{
        width: 140%;
    }

    .mail{
        right: -0.5rem;
    }
}